export default (msg) => {
    localStorage.setItem("accessToken", msg.accessToken);
    localStorage.setItem("conpanyId", msg.e_id);
    localStorage.setItem("token", msg.token);
    localStorage.setItem("userId", msg.id);
    localStorage.setItem("phone", msg.mobile);
    localStorage.setItem("loginTime", Date.parse(new Date()));
    localStorage.setItem("reg_company", msg.reg_company);
    localStorage.setItem("cachephone", msg.mobile); //缓存手机号 用于登录时展示
    localStorage.removeItem("guidepage");
}