<template>
  <div class="theall" v-wechat-title="$route.meta.title">
    <div class="topbackclass"></div>
    <div class="centerclass">
      <div class="centerchild">
        <div class="logoclass">
          <img src="../../assets/login_logo.png" alt="" />
        </div>
        <div class="inputmsgclass">
          <input
            v-model="phone"
            class="shoujihao"
            type="number"
            name=""
            id=""
            placeholder="请输入手机号"
          />
        </div>
        <div v-show="isCodeOrPass" class="inputmsgclass inputmsgclass2">
          <input
            v-model="code"
            class="shoujihao"
            type="number"
            name=""
            id=""
            placeholder="请输入验证码"
          />
          <div
            :style="
              getcode == '获取验证码' ? '' : 'width:2.3rem;color:#909399;'
            "
            @click="cligetcode"
          >
            {{ getcode == "获取验证码" ? getcode : `${getcode}s后可重新重发` }}
          </div>
        </div>
        <div v-show="!isCodeOrPass" class="inputmsgclass inputmsgclass2">
          <input
            v-model="password"
            class="shoujihao"
            :type="canShowPass ? 'text' : 'password'"
            name=""
            id=""
            placeholder="请输入密码"
          />
          <van-icon
            @click="canShowPass = !canShowPass"
            style="margin-right: 0.1rem; width: 0.38rem"
            color="#909399"
            size=".4rem"
            :name="canShowPass ? 'eye-o' : 'closed-eye'"
          />
        </div>
        <div class="fangshiclass">
          <div @click="toregistmeth">快速注册</div>
          <div
            @click="$router.push('/modifypass')"
            v-show="!isCodeOrPass"
            style="color: #909399"
          >
            忘记密码
          </div>
        </div>
        <div
          ontouchstart=""
          @click="isCodeOrPass ? codego() : go()"
          :class="cantologin ? 'loginclass2' : 'loginclass'"
        >
          {{ isCodeOrPass == true ? "验证码登录" : "密码登录" }}
        </div>
        <div @click="isCodeOrPass = !isCodeOrPass" class="loginmethclass">
          {{ isCodeOrPass == true ? "密码登录" : "验证码登录" }}
        </div>
      </div>
    </div>
    <div class="xieyi">
      登录注册即代表同意<span @click.stop="$router.push('/serviceprotocol')"
        >《萝卜猎手服务协议》</span
      >
    </div>
    <sharepage></sharepage>
  </div>
</template>
<script>
// localStorage.setIem("", "");
// localStorage.getItem();
// @click="
var intervalID;
import { Dialog } from "vant";
import loginmeth from "../../../util/logintime";
import sharepage from "../../views/sharepage/index";
import cryptoJs from "crypto-js";
import loginlocal from "../../../util/loginlocal";
export default {
  data() {
    return {
      mobile: "",
      password: "",
      phone: "", //手机号
      code: "", //验证码
      incode: "", //邀请码
      getcode: "获取验证码", //获取验证码
      isPass: false,
      isCode: true,
      onelogin: false, //是否可以一键返回
      urlshareesmsg: "", //是否是分享进入登录
      sharemm: "",
      cantologin: false,
      isCodeOrPass: true, //true:验证码登录 false：密码登录
      canShowPass: false,
      intervalID: "-1",
      loginCode: "",
      keyOne: "313233343536373a",
      otherPhone: "",
    };
  },
  components: {
    sharepage,
  },
  created() {
    this.tosharemeth();
    this.getAndSetLink();
    if (localStorage.getItem("cachephone")) {
      this.phone = localStorage.getItem("cachephone");
    }
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
  },
  watch: {
    phone(newmsg) {
      if (/^1[3-9]\d{9}$/.test(newmsg)) {
        this.cantologin = true;
      } else {
        this.cantologin = false;
      }
    },
    code(oldmsg) {
      if ((oldmsg + "").length > 6) {
        this.code = (oldmsg + "").substr(0, 6);
      }
    },
  },
  mounted() {
    this.onelogin = loginmeth();
    setTimeout(() => {
      if (this.onelogin) {
        this.$router.push("/home");
      }
    });

    if (this.$route.query.login_submsg) {
      this.urlshareesmsg = this.$route.query.urlsharees;
    }
  },
  methods: {
    //获取管理端生成的链接
    getAndSetLink() {
      if (this.$route.query.loginCode) {
        this.loginCode = this.$route.query.loginCode;
      }
      this.otherPhone = this.decryCode(this.loginCode);
    },
    //解密
    decryCode(word) {
      if (word) {
        let key = cryptoJs.enc.Hex.parse(this.keyOne);
        let dec = cryptoJs.AES.decrypt(cryptoJs.format.Hex.parse(word), key, {
          // vi: vi
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        });
        let decData = cryptoJs.enc.Utf8.stringify(dec);
        return decData;
      } else {
        return null;
      }
    },
    //
    toregistmeth() {
      //分享简历进入
      if (this.urlshareesmsg == "/sub") {
        this.$router.push({
          path: "/register",
          query: {
            login_submsg: JSON.stringify(this.sharemm),
          },
        });
      } else {
        this.$router.push("/register");
      }
    },
    tosharemeth() {
      let obj = JSON.stringify({
        sharetitle: "萝卜猎手背景调查",
        sharedesc: "一秒钟还原履历真实性，即刻体验~",
        isshare: "1",
        share_phone: localStorage.getItem("phone"),
      });
      localStorage.setItem("shareobj", obj);
    },
    /**
     * 点击一键返回
     */
    codego2() {
      this.$router.push("/home");
    },
    /**
     * 验证码登录
     */
    codego() {
      if (!this.cantologin) {
        return;
      }
      if (this.phone == "") {
        Dialog({ message: "请输入正确手机号" });
        return;
      } else if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        Dialog({ message: "请输入正确手机号" });
        return;
      }
      this.$toast.loading();
      this.$http
        .post("/firm/v1/login/login_phone", {
          reqType: "login",
          mobile: this.phone, //手机号码
          mobile_code: this.code, //验证码
          is_link: this.otherPhone ? 1 : 0, //为1 是否链接进
          otherPhone: this.otherPhone, //--邀请手机
          login_type: 1,
        })
        .then((res) => {
          const arr = JSON.parse(res.data);
          if (arr.code === "6007" || arr.code === "6006") {
            this.$toast.fail("登录失败");
            return;
          }
          this.$toast.success("登录成功");

          loginlocal(arr.data);
          this.$router.push("/home");
          // if (this.$route.query.type == 1) {
          //   this.$router.go(-1);
          // } else {
          //   this.$router.push("/home");
          // }
          // if (this.urlshareesmsg) {
          //   this.$router.push(this.urlshareesmsg);
          // } else {
          //   if (this.$route.query.type == 1) {
          //     this.$router.go(-1);
          //   } else {
          //     this.$router.push("/home");
          //   }
          // }
        })
        .catch((res) => {});
    },

    /**
     * 账号密码登录
     */
    go() {
      if (!this.cantologin) {
        return;
      }
      this.$toast.loading();
      this.$http
        .post("/firm/v1/login/login", {
          reqType: "login",
          mobile: this.phone,
          password: this.password,
          is_link: this.otherPhone ? 1 : 0, //为1 是否链接进
          otherPhone: this.otherPhone, //--邀请手机
          login_type: 1,
        })
        .then((res) => {
          const arr = JSON.parse(res.data);
          console.log(arr);
          if (arr.code === "6007" || arr.code === "6006") {
            this.$toast.fail("手机号或密码错误");
            return;
          }
          this.$toast.success("登录成功");
          loginlocal(arr.data);
          this.$router.push("/home");
          // if (this.$route.query.type == 1) {
          //   this.$router.go(-1);
          // } else {
          //   this.$router.push("/home");
          // }
          // if (this.urlshareesmsg) {
          //   this.$router.push(this.urlshareesmsg);
          // } else {
          //   //其他页面进入，登录后进入开始的页面
          //   if (this.$route.query.type == 1) {
          //     this.$router.go(-1);
          //   } else {
          //     this.$router.push("/home");
          //   }
          // }
        })
        .catch((err) => {});
    },
    /**
     * 计时器
     */
    timeing() {
      this.getcode = 60;
      this.intervalID = setInterval(() => {
        this.getcode--;

        if (this.getcode < 1) {
          clearInterval(this.intervalID);
          this.getcode = "获取验证码";
        }
      }, 1000);
    },

    /**
     * 停止计时器
     */
    destroyed() {
      clearInterval(this.intervalID);
    },

    /**
     * 获取验证码
     */
    cligetcode() {
      if (this.getcode != "获取验证码") {
        return;
      }
      if (this.phone == "") {
        Dialog({ message: "请输入正确手机号" });
        return;
      } else if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        Dialog({ message: "请输入正确手机号" });
        return;
      }

      this.timeing();

      //获取验证码
      this.sendcode();
    },

    /**
     * 发送验证码
     */
    sendcode() {
      this.$http
        .post("/firm/v1/login/valid_send", {
          reqType: "login",
          mobile: this.phone,
          source: "login",
          login_type: 1,
        })
        .then((res) => {
          this.$toast("发送成功");
        })
        .catch((res) => {});
    },
  },
};
</script>
<style scoped>
.loginmethclass {
  font-size: 0.28rem;
  font-weight: 500;
  color: #606266;
  line-height: 0.48rem;
  text-align: center;
  margin-top: 0.28rem;
}
.loginclass {
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.44rem;
  text-shadow: 0px 0px 0px rgba(249, 137, 53, 0.59);
  padding: 0.26rem 0;
  text-align: center;
  background: linear-gradient(90deg, #f85615 0%, #f98d37 100%);
  box-shadow: 0rem 0.08rem 0.16rem 0rem rgba(249, 137, 53, 0.59);
  border-radius: 0.48rem;
  opacity: 0.6;
  margin-top: 0.5rem;
}
.loginclass2 {
  text-align: center;
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.44rem;
  text-shadow: 0px 0px 0px #ffceaa;
  padding: 0.26rem 0;
  background: linear-gradient(90deg, #f85615 0%, #f98d37 100%);
  box-shadow: 0rem 0.08rem 0.16rem 0rem #ffceaa;
  border-radius: 0.48rem;
  margin-top: 0.5rem;
}
.loginclass2:active {
  transform: scale(0.9);
}
.fangshiclass {
  font-size: 0.28rem;
  font-weight: 400;
  color: rgba(69, 113, 208, 0.9);
  line-height: 0.48rem;
  padding-top: 0.2rem;
  padding-left: 0.08rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputmsgclass2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inputmsgclass2 div {
  width: 1.6rem;
  font-size: 0.28rem;
  font-weight: 500;
  color: rgba(254, 89, 3, 0.75);
  line-height: 0.48rem;
}
.inputmsgclass2 input {
  flex: 1;
}
.inputmsgclass {
  padding: 0.28rem 0;
  border-bottom-width: 1px;
  border-bottom-color: #efefef;
  border-bottom-style: solid;
}
.shoujihao {
  width: 100%;
  font-size: 0.32rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
  border: none;
}
.logoclass {
  text-align: center;
  margin-bottom: 0.58rem;
}
.logoclass img {
  width: 4.34rem;
  margin: 0;
}
.xieyi {
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
  text-align: center;
  margin-top: 1vh;
}
.xieyi span {
  color: #fe5903;
}
.centerclass {
  background-color: #ffffff;
  height: 69vh;
  margin: 0 0.24rem;
  border-radius: 0.3rem;
  margin-top: -15vh;
  padding: 5vh 7vw;
}
.topbackclass {
  height: 30vh;
  background-image: url("../../assets/login_back.png");
  background-size: 100% 100%;
}
.ti {
  font-size: 0.2rem;
}
.bt2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.theall {
  background-color: #f6f6f7;
  min-height: 100%;
  overflow: hidden;
}

.theall > p {
  text-align: center;
  margin-top: 0.2rem;
  color: #ef811a;
  font-size: 0.34rem;
  font-weight: 500;
}
.way {
  display: flex;
  justify-content: center;
}
.loginway {
  margin-top: 0.9rem;
  display: flex;
  justify-content: center;
  padding-bottom: 0.12rem;
  border-bottom-color: #c9ced6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.loginway > div:first-child {
  margin-right: 0.73rem;
}
.waycssone {
  color: #333333;
  font-size: 0.32rem;
  font-weight: 400;
}
.waycsstwo {
  color: #f55613;
  border-bottom-width: 2px;
  border-bottom-style: #f55613;
  border-block-end-style: solid;
  margin-bottom: -0.13rem;
}
.userImage {
  text-align: center;
  margin-bottom: 0.5rem;
}
img {
  width: 2.47rem;
  margin-top: 0.6rem;
}
.alldata {
  padding-top: 0.7rem;
  margin: 0 0.48rem;
}
.alldata input {
  font-size: 0.32rem;
}
.data {
  border-bottom-color: #ebe8e8;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 0.26rem 0;
  font-size: 20px;
}
.fx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fx > input {
  width: 3.5rem;
  padding: 0;
}
.fx > p {
  line-height: 1px;
  color: #ef811a;
  font-size: 0.32rem;
}
::placeholder {
  color: #c9ced6;
}

.data > input {
  border: none;
}
.loginP {
  color: #ef821a;
  font-size: 0.3rem;
}
.lp {
  border: none;
  display: flex;
  justify-content: space-between;
}

.protocol {
  text-align: center;
  font-size: 13px;
  margin-top: 0.3rem;
  color: #c9ced6;
}
.bt {
  margin-top: 0.53rem;
}
.btdiv {
  padding-bottom: 0.3rem;
}
</style>
